<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container" style="margin-bottom: 40px;">
      <a-steps progress-dot :current="step" :initial="1">
        <a-step title="Step 1" description="Enter Equipment Details" />
        <a-step title="Step 2" description="Confirmation" />
        <a-step title="Step 3" description="Complete" />
      </a-steps>
    </div>
    <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
    <div v-if="step === 1 && loading === false">
      <div class="container">
        <div style="display: flex; flex-direction: column; gap: 8px;">
          <span style="display: block;">
            Equipment listings are only available on the website - they will not show up on the apps.
          </span>
          <span class="remarks-text" style="display: block;">
            Ad plans include the $8.99 Basic plan which includes the pin on the map and drop down list or the $24.99 Premium plan which includes a scrolling ad on the website. Ad plan options will show after you click "Preview Listing" then scroll down to "Choose Your Ad Plan".
          </span>
        </div>
        <hr style="margin: 2rem 0;" />
        <div class="form-group">
          <label>Listing Name <span class="remarks-text">(Example: 2017 Mac Rider Dressage Saddle Medium Tree)</span></label>
          <a-input v-model:value="form.listing_name" />
        </div>
        <div class="form-group">
          <label>Select an item to advertise <span class="remarks-text">(If you don't see your item - please email <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a> and we can add a new category. Choose only one item per listing.)</span></label>
          <div>
            <a-select v-model:value="form.type" style="width: 100%" :virtual="false">
              <a-select-optgroup label="Horse">
                <a-select-option :value="type" :key="type" v-for="type in horse_equipment_types">{{type}}</a-select-option>
              </a-select-optgroup>
              <a-select-optgroup label="People">
                <a-select-option :value="type" :key="type" v-for="type in people_equipment_types">{{type}}</a-select-option>
              </a-select-optgroup>
            </a-select>
          </div>
        </div>
        <div class="form-group">
          <label>Price</label>
          <a-input v-model:value="form.price" prefix="$" />
        </div>
        <div class="form-group">
          <label>Condition of item</label>
          <div>
            <a-radio-group v-model:value="form.condition">
              <div style="margin-top: 10px;">
                <a-radio :value="EquipmentCondition.New">New</a-radio>
              </div>
              <div class="d-flex justify-content-start align-items-center" style="margin-top: 10px;">
                <a-radio :value="EquipmentCondition.Used">Used</a-radio>
                <a-input v-model:value="form.condition_years" style="width: 150px; margin-left: 30px;" suffix="year(s)" :disabled="form.condition != EquipmentCondition.Used" />
              </div>
            </a-radio-group>
          </div>
        </div>
        <div class="form-group">
          <label>Brand</label>
          <a-input v-model:value="form.brand" />
        </div>
        <div class="form-group">
          <label>Size</label>
          <a-input v-model:value="form.size" />
        </div>
        <div class="form-group">
          <label>Discipline</label>
          <a-input v-model:value="form.discipline" />
        </div>
        <div class="form-group">
          <label>Model</label>
          <a-input v-model:value="form.model" />
        </div>
        <div class="form-group">
          <label>Location <span class="remarks-text" style="font-size: 0.9rem; color: gray;">(Please select from address list. You may simply enter a city and state. Or, enter address and click on Do Not Show Address button if you do not want your address to show on listing.)</span></label>
          <input type="text" id="autocomplete_search" class="form-control" :disabled="mapAutoComplete === null" :placeholder="mapAutoComplete === null ? 'Loading...' : 'Enter a location'" :value="this.form.address.address" />
        </div>
        <div class="form-group">
          <a-checkbox v-model:checked="form.address.hide">Do not show my street address.</a-checkbox>
        </div>
        <div class="form-group">
          <label>Shipping Details <span class="remarks-text">(Note: Specify if the item is pick up/collection-only or if it can be shipped and who is responsible for paying for shipping and how much the shipping costs.)</span></label>
          <a-input v-model:value="form.shipping" />
        </div>
        <div class="form-group">
          <label>Description <span class="remarks-text">(Please describe your item in full detail. If it's a saddle make sure to include the year, make, model, tree width, seat and leather. All other items please be as detailed as possible.)</span></label>
          <div>
            <a-textarea v-model:value="form.description" :rows="5" showCount :maxlength="1000" />
          </div>
        </div>
        <div style="margin-top: 20px;">
          <label>Upload Photos <span class="remarks-text">(Maximum 5 photos. The photo on top will be the primary photo on listing. To reorder the photos, drag and drop in the order you prefer - primary photo on top.)</span></label>
          <label><span class="remarks-text">(Note: Horizontal photos display better than vertical photos.)</span></label>
          <div>
            <input type="file" ref="images" accept="image/png, image/jpeg, image/heic, image/heic-sequence" data-max-files="5" />
          </div>
        </div>
        <div style="margin-top: 20px;">
          <h4 style="text-align: center; margin-bottom: 20px;">Your contact information</h4>
          <div class="form-group">
            <div>
              <a-radio-group v-model:value="form.contact_information">
                <div><a-radio value="default">Use my account contact information</a-radio></div>
                <div><a-radio value="other">Use other contact information</a-radio></div>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Name</label>
          <a-input v-model:value="form.contact.name" />
        </div>
        <div class="form-group">
          <label>Phone</label>
          <a-input v-model:value="form.contact.phone" />
        </div>
        <div class="form-group">
          <label>Email</label>
          <a-input v-model:value="form.contact.email" />
        </div>
        <div class="form-group">
          <label>How do you want potential buyers to contact you?</label>
          <div>
            <a-checkbox v-model:checked="form.allowCall" style="width: 100%; margin: 0;">Call</a-checkbox>
            <a-checkbox v-model:checked="form.allowText" style="width: 100%; margin: 0;">Text</a-checkbox>
            <a-checkbox v-model:checked="form.allowEmail" style="width: 100%; margin: 0;">Email</a-checkbox>
          </div>
        </div>
      </div>
      <div class="buttons-container">
        <div class="container">
          <a-checkbox v-model:checked="form.accept_disclaimer" style="width: 100%; margin: 0;">I confirm this listing contains only one individual item, in accordance with the terms of use</a-checkbox>
        </div>
        <div class="container d-flex justify-content-end" style="margin-top: 16px;">
          <button class="btn btn-outline-primary" @click="saveDraft()" :disabled="imagesAdded !== imagesUploaded" style="margin-right: 8px;">Save Draft</button>
          <button class="btn btn-primary" @click="previewListing()" :disabled="imagesAdded !== imagesUploaded">Preview Listing</button>
        </div>
      </div>
    </div>
    <div v-if="step === 2 && loading === false">
      <Detail :item="form" :type="'equipment'" />
      <hr />
      <div class="container">
        <div class="d-flex justify-content-between">
          <button class="btn btn-info" @click="step = 1">Edit Equipment</button>
          <router-link class="btn btn-primary" :to="{path: '/plans', query: {next_page: 'save-equipment', next_id: form.id}}" v-if="form.subscription_id == null && form.trainer_package_subscription_id == null">Choose Your Ad Plan</router-link>
          <button class="btn btn-primary" @click="publishListing()" v-if="form.subscription_id != null || form.trainer_package_subscription_id != null">Publish Listing</button>
        </div>
      </div>
    </div>
    <div v-if="step === 3 && loading === false">
      <div class="container">
        <h4 style="text-align: center; margin: 20px 0;">Success!</h4>
        <p style="text-align: center">Thank you for listing your equipment for sale with Mane Street Market.</p>
        <p style="text-align: center">Your listing is now live on the website.</p>
      </div>
      <hr />
      <div class="container">
        <div class="d-flex justify-content-between">
          <div>
            <button class="btn btn-info" @click="step = 1">Edit Listing</button>
            <router-link class="btn btn-info" style="margin-left: 8px;" :to="{path: '/search-equipment', query: {id: form.id}}">View Listing</router-link>
            <button class="btn btn-outline-info" style="margin-left: 8px;" @click="resetForm()">List Another Equipment</button>
          </div>
          <router-link class="btn btn-primary" to="/my-equipment">Close</router-link>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';
import axios from 'axios';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import Detail from '@/components/Detail.vue';
import * as FilePond from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import heic2any from 'heic2any';
import { fromBlob } from 'image-resize-compress';
import { EquipmentCondition } from '@/enums/equipment_condition';
import { storage as firebaseStorage } from '@/firebase';

// AntVue
import {UploadOutlined} from '@ant-design/icons-vue';
import ASteps from 'ant-design-vue/lib/steps';
import AInput from 'ant-design-vue/lib/input';
import AInputNumber from 'ant-design-vue/lib/input-number';
import ARadio from 'ant-design-vue/lib/radio';
import ACheckbox from 'ant-design-vue/lib/checkbox';
import ASelect from 'ant-design-vue/lib/select';
import ASpin from 'ant-design-vue/lib/spin';
import AUpload from 'ant-design-vue/lib/upload';
import AButton from 'ant-design-vue/lib/button';
import 'ant-design-vue/lib/steps/style/index.css';
import 'ant-design-vue/lib/input/style/index.css';
import 'ant-design-vue/lib/input-number/style/index.css';
import 'ant-design-vue/lib/radio/style/index.css';
import 'ant-design-vue/lib/checkbox/style/index.css';
import 'ant-design-vue/lib/select/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import 'ant-design-vue/lib/upload/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import Swal from "sweetalert2";
import ConstantsService from '@/services/constants_service';
import Constants from '@/constants/constants';
import HorseEquipmentTypes from '@/constants/horse_equipment_types';
import PeopleEquipmentTypes from '@/constants/people_equipment_types';
import store from '@/store';

export default {
  components: {
    BaseLayout,
    SubHeader,
    AInput,
    ASelect,
    ASpin,
    Detail,
    AUpload,
    AButton,
    UploadOutlined,
    'a-checkbox': ACheckbox,
    'file-pond': FilePond,
    'a-steps': ASteps,
    'a-step': ASteps.Step,
    'a-textarea': AInput.TextArea,
    'a-input-number': AInputNumber,
    'a-radio': ARadio,
    'a-radio-button': ARadio.Button,
    'a-radio-group': ARadio.Group,
    'a-select-option': ASelect.Option,
    'a-select-optgroup': ASelect.OptGroup
  },
  data() {
    return {
      loading: false,
      imagesAdded: 0,
      imagesUploaded: 0,
      step: 1,
      formatter: null,
      google_maps_loader: null,
      mapAutoCompleteListener: null,
      mapAutoComplete: null,
      pond: null,
      new_images: [],
      EquipmentCondition,
      horse_equipment_types: HorseEquipmentTypes,
      people_equipment_types: PeopleEquipmentTypes,
      form: {
        id: null,
        subscription_id: null,
        trainer_package_subscription_id: null,
        listing_name: null,
        type: null,
        address: {
          address: null,
          state: null,
          city: null,
          country: null,
          postal_code: null,
          lat: null,
          lng: null,
          hide: false
        },
        description: null,
        contact_information: 'default',
        contact: {
          name: this.$store.state.user.displayName,
          phone: this.$store.state.user.phoneNumber,
          email: this.$store.state.user.email,
          type: 'email'
        },
        allowCall: false,
        allowText: false,
        allowEmail: false,
        images: [],
        price: null,
        condition: EquipmentCondition.New,
        condition_years: null,
        brand: null,
        size: null,
        discipline: null,
        model: null,
        shipping: null,
        accept_disclaimer: false
      }
    }
  },
  async mounted() {
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    document.title = 'Create Equipment - Mane Street Market';
    window.scrollTo(0, 0);
    if (this.$route.query.id) {
      await this.loadListing(this.$route.query.id)
    }
    this.google_maps_loader = new Loader({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      libraries: ['places'],
      version: Constants.GOOGLE_MAPS_VERSION
    });
    await this.google_maps_loader.load();
    if (this.$route.query.payment_completed && (this.form.subscription_id != null || this.form.trainer_package_subscription_id != null || this.$store.state.is_trainer_package_subscriber)) {
      this.step = 3;
      this.publishListing();
    }
    if (this.step === 1) {
      this.setupForm();
    }
  },
  unmounted() {
    if (this.mapAutoCompleteListener) {
      window.google.maps.event.removeListener(this.mapAutoCompleteListener);
    }
    if (this.mapAutoComplete) {
      window.google.maps.event.clearInstanceListeners(this.mapAutoComplete);
    }
    this.google_maps_loader.deleteScript();
  },
  watch: {
    step(newValue) {
      window.scrollTo(0, 0);
      if (newValue === 1) {
        this.setupForm();
      }
    },
    'form.price'(newValue) {
      if (newValue) {
        let price = newValue.toString().replace(/[^\d]/g, '');
        this.form.price = price;
      }
    },
    'form.condition_years'(newValue) {
      if (newValue) {
        let years = newValue.toString().replace(/[^\d]/g, '');
        this.form.condition_years = years;
      }
    },
    'form.contact_information'() {
      if (this.form.contact_information === 'default') {
        this.form.contact.name = this.$store.state.user.displayName;
        this.form.contact.email = this.$store.state.user.email;
        this.form.contact.phone = this.$store.state.user.phoneNumber;
      } else {
        this.form.contact.name = null;
        this.form.contact.email = null;
        this.form.contact.phone = null;
      }
    }
  },
  methods: {
    setupForm() {
      setTimeout(async () => {
        const imageUrls = [];
        if (this.new_images != null && this.new_images.length > 0) {
          for (const image of this.new_images) {
            if (image.file.type != null) { // newly-added image
              imageUrls.push(image.file);
            } else {
              imageUrls.push({
                source: image.data,
                options: {
                  file: {
                    ...image.file
                  }
                }
              });
            }
          }
          this.new_images = [];
        } else if (this.form.images != null && this.form.images.length > 0) {
          for (const imageUrl of this.form.images) {
            const ref = firebaseStorage.refFromURL(imageUrl);
            const downloadUrl = await ref.getDownloadURL();
            imageUrls.push(downloadUrl);
          }
        }
        FilePond.registerPlugin(
          FilePondPluginFileValidateType,
          FilePondPluginImagePreview,
          FilePondPluginFileEncode,
          FilePondPluginImageResize,
          FilePondPluginImageTransform,
          FilePondPluginFileValidateSize
        );
        this.pond = FilePond.create(this.$refs.images, {
          allowFileEncode: true,
          allowMultiple: true,
          acceptedFileTypes: ['image/png', 'image/jpeg', 'image/heic', 'image/heic-sequence'],
          fileValidateTypeDetectType: (source, type) => new Promise((resolve, reject) => {
            let name = source.name.toLowerCase();
            if (name.includes('.heic')) {
              resolve('image/heic');
            } else if (name.includes('.heics')) {
              resolve('image/heic-sequence');
            } else {
              resolve(type);
            }
          }),
          imageResizeTargetWidth: 1000,
          imageResizeTargetHeight: 1000,
          imageResizeUpscale: false,
          imageTransformOutputQuality: 60,
          imageTransformOutputMimeType: 'image/jpeg',
          maxFileSize: '10MB',
          labelMaxFileSizeExceeded: 'Photo too large. Please compress to a max of 10MB.',
          allowReorder: true,
          files: imageUrls
        });

        this.pond.oninitfile = (f) => {
          this.imagesAdded++;
        };
        this.pond.onaddfile = (err, f) => {
          this.imagesUploaded++;
        };

        this.mapAutoComplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete_search'));
        this.mapAutoComplete.setComponentRestrictions({
          country: ["us", "ca", "ie", "uk"],
        });
        this.mapAutoCompleteListener = window.google.maps.event.addListener(this.mapAutoComplete, 'place_changed', this.searchOnMap);

        if (this.form.address.address) {
          document.getElementById('autocomplete_search').value = this.form.address.address;
        }
      }, 2000);
    },
    async loadListing(id) {
      this.loading = true;
      try {
        let response = await axios.get('/api/equipments/' + id);
        this.form = {
          id: id,
          listing_name: response.data.listing_name || null,
          subscription_id: response.data.subscription_id || null,
          trainer_package_subscription_id: response.data.trainer_package_subscription_id || null,
          type: response.data.type,
          description: response.data.description,
          contact_information: response.data.contact_information,
          contact: response.data.contact || {
            name: null,
            phone: null,
            email: null,
            type: 'email'
          },
          allowCall: response.data.allowCall === undefined || response.data.allowCall,
          allowText: response.data.allowText === undefined || response.data.allowText,
          allowEmail: response.data.allowEmail === undefined || response.data.allowEmail,
          images: response.data.images || [],
          address: response.data.address || {
            address: null,
            lat: null,
            lng: null,
            postal_code: null,
            city: null,
            state: null,
            country: null,
            hide: false
          },
          price: response.data.price,
          condition: response.data.condition,
          condition_years: response.data.condition_years,
          brand: response.data.brand,
          size: response.data.size,
          discipline: response.data.discipline,
          model: response.data.model,
          shipping: response.data.shipping
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    searchOnMap() {
      let place = this.mapAutoComplete.getPlace();
      this.form.address.address = place.formatted_address;
      this.form.address.postal_code = null;
      this.form.address.city = null;
      this.form.address.state = null;
      this.form.address.country = null;
      this.form.address.lat = place.geometry['location'].lat();
      this.form.address.lng = place.geometry['location'].lng();
      for (const component of place.address_components) {
        const addressTypes = component.types;
        if (addressTypes.includes('postal_code')) {
          this.form.address.postal_code = component.short_name;
        } else if (addressTypes.includes('country')) {
          this.form.address.country = component.long_name;
        } else if (addressTypes.includes('administrative_area_level_1')) {
          this.form.address.state = component.short_name;
        } else if (addressTypes.includes('locality')) {
          this.form.address.city = component.long_name;
        } else if (addressTypes.includes('administrative_area_level_3') && this.form.address.city == null) { // do not overwrite locality
          this.form.address.city = component.long_name;
        }
      }
    },
    async saveDraft() {
      this.loading = true;

      try {
        let response = await axios.post('/api/equipments/listing-name', {
          id: this.form.id,
          listing_name: this.form.listing_name,
        });
        this.form.id = response.data.id;
      } catch (error) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.loading = false;
        this.setupForm();
        return;
      }

      try {
        this.new_images = [];
        for (let image of this.pond.getFiles()) {
          if (image.fileExtension === 'heic' || image.fileExtension === 'heics') {
            const blob = this.b64toBlob(image.getFileEncodeBase64String())
            const jpgBlobs = await heic2any({
              blob,
              toType: 'image/jpeg',
              quality: 1,
              multiple: true
            });
            for (let jpgBlob of jpgBlobs) {
              const smallerJpg = await fromBlob(jpgBlob, 60, 1000, 'auto', 'jpeg');
              const b64 = await this.blobToBase64(smallerJpg);
              this.new_images.push({
                file: image.file,
                size: image.fileSize,
                extension: 'jpeg',
                data: b64.replace('data:image/jpeg;base64,', '')
              });
            }
          } else {
            this.new_images.push({
              file: image.file,
              size: image.fileSize,
              extension: image.fileExtension,
              data: image.getFileEncodeBase64String()
            });
          }
        }
        let response = await axios.post('/api/equipments/save-draft', {
          id: this.form.id,
          listing_name: this.form.listing_name,
          type: this.form.type,
          description: this.form.description,
          contact_information: this.form.contact_information,
          contact: this.form.contact,
          allowCall: this.form.allowCall,
          allowText: this.form.allowText,
          allowEmail: this.form.allowEmail,
          images: this.form.images,
          new_images: this.new_images,
          address: this.form.address,
          price: this.form.price,
          condition: this.form.condition,
          condition_years: this.form.condition_years,
          brand: this.form.brand,
          size: this.form.size,
          discipline: this.form.discipline,
          model: this.form.model,
          shipping: this.form.shipping
        });
        this.form.images = response.data.images;
        Swal.fire({
          title: 'Draft saved',
          text: 'Your listing is now saved as draft'
        });
        this.$router.push({ path: '/my-equipment', replace: true });
      } catch (error) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.setupForm();
      } finally {
        this.loading = false;
      }
    },
    async previewListing() {
      if (!this.form.accept_disclaimer) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please check the box to confirm this listing contains only one individual item'
        });
        return;
      }

      if (!this.form.allowCall && !this.form.allowText && !this.form.allowEmail) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select at least one form of contact'
        });
        return;
      }

      if (this.form.address.address == null) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select an address in the drop down list. If you do not want address to show on listing, click "Do Not Show Address".'
        });
        return;
      }

      if (this.form.type == null) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select an equipment type'
        });
        return;
      }

      this.loading = true;

      try {
        let response = await axios.post('/api/equipments/listing-name', {
          id: this.form.id,
          listing_name: this.form.listing_name,
        });
        this.form.id = response.data.id;
      } catch (error) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.loading = false;
        this.setupForm();
        return;
      }

      try {
        this.new_images = [];
        for (let image of this.pond.getFiles()) {
          if (image.fileExtension === 'heic' || image.fileExtension === 'heics') {
            const blob = this.b64toBlob(image.getFileEncodeBase64String())
            const jpgBlobs = await heic2any({
              blob,
              toType: 'image/jpeg',
              quality: 1,
              multiple: true
            });
            for (let jpgBlob of jpgBlobs) {
              const smallerJpg = await fromBlob(jpgBlob, 60, 1000, 'auto', 'jpeg');
              const b64 = await this.blobToBase64(smallerJpg);
              this.new_images.push({
                file: image.file,
                size: image.fileSize,
                extension: 'jpeg',
                data: b64.replace('data:image/jpeg;base64,', '')
              });
            }
          } else {
            this.new_images.push({
              file: image.file,
              size: image.fileSize,
              extension: image.fileExtension,
              data: image.getFileEncodeBase64String()
            });
          }
        }
        let response = await axios.post('/api/equipments/details', {
          id: this.form.id,
          listing_name: this.form.listing_name,
          type: this.form.type,
          description: this.form.description,
          contact_information: this.form.contact_information,
          contact: this.form.contact,
          allowCall: this.form.allowCall,
          allowText: this.form.allowText,
          allowEmail: this.form.allowEmail,
          images: this.form.images,
          new_images: this.new_images,
          address: this.form.address,
          price: this.form.price,
          condition: this.form.condition,
          condition_years: this.form.condition_years,
          brand: this.form.brand,
          size: this.form.size,
          discipline: this.form.discipline,
          model: this.form.model,
          shipping: this.form.shipping,
          accept_disclaimer: this.form.accept_disclaimer
        });
        this.form.images = response.data.images;
        this.step = 2;
      } catch (error) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.setupForm();
      } finally {
        this.loading = false;
      }
    },
    async publishListing() {
      this.loading = true;
      try {
        await axios.post('/api/equipments/publish', {id: this.form.id});
        this.step = 3;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    resetForm() {
      this.form = {
        id: null,
        subscription_id: null,
        listing_name: null,
        type: null,
        address: {
          address: null,
          state: null,
          city: null,
          country: null,
          postal_code: null,
          lat: null,
          lng: null,
          hide: false
        },
        description: null,
        contact_information: 'default',
        contact: {
          name: this.$store.state.user.displayName,
          phone: this.$store.state.user.phoneNumber,
          email: this.$store.state.user.email,
          type: 'email'
        },
        allowCall: false,
        allowText: false,
        allowEmail: false,
        images: [],
        price: null,
        condition: EquipmentCondition.New,
        condition_years: null,
        brand: null,
        size: null,
        discipline: null,
        model: null,
        shipping: null,
        accept_disclaimer: false
      };
      this.$router.replace({ query: null });
      this.step = 1;
    }
  }
}
</script>

<style lang="scss" scoped>
  .remarks-text {
    font-size: 0.9rem;
    color: gray;
  }

  .buttons-container {
    position: sticky;
    bottom: 0;
    padding: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    max-width: none;
    margin-bottom: -1rem;
    background: white;
  }

  ::v-deep(.ant-steps) {
    padding-top: 4px;
    overflow: hidden;
    justify-content: center;

    .ant-steps-item {
      max-width: 33%;
    }

    .ant-steps-item-process .ant-steps-item-icon {
      width: 8px;
      height: 8px;
    }
  }
</style>
